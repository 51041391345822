import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { Carousel, CarouselIndicators, CarouselItem, Col, Container, Row } from 'reactstrap';
import Img from 'gatsby-image';

import '../assets/scss/components/_testimonialsSection.scss';

class Testimonials extends Component {
  constructor(props) {
    super(props);
    this.state = { activeIndex: 0 };

    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.goToIndex = this.goToIndex.bind(this);
    this.onExiting = this.onExiting.bind(this);
    this.onExited = this.onExited.bind(this);
  }

  onExiting() {
    this.animating = true;
  }

  onExited() {
    this.animating = false;
  }

  next() {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === this.props.items.length - 1 ? 0 : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  }

  previous() {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === 0 ? this.props.items.length - 1 : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  }

  goToIndex(newIndex) {
    if (this.animating) return;
    this.setState({ activeIndex: newIndex });
  }

  render() {
    const { activeIndex } = this.state;

    const slides = this.props.items.map((item, index) => {
      return (
        <CarouselItem
          onExiting={this.onExiting}
          onExited={this.onExited}
          key={index}
        >
          <Row className=" justify-content-center g-pos-rel">
            <Col lg={7} className="g-col">
              <div className="media position-relative">
                <em className="quotation-marks">
                  “
                </em>
                {item.image ? (
                  <Img
                    className="d-flex align-self-center rounded-circle mr-3"
                    alt={item.name}
                    fixed={item.image.childImageSharp.fixed}
                  />
                ) : null}
                <div className="media-body text-left mt-4 ml-2">
                  {item.name ? (
                    <h4 className="h5">
                      {item.name}
                    </h4>
                  ) : null}
                  {item.job ? (
                    <span className="d-block">
                        {item.job}
                      </span>
                  ) : null}
                </div>
              </div>
              <div>
                {item.blockquote ? (
                  <blockquote className="lead">
                    {item.blockquote}
                  </blockquote>
                ) : null}
              </div>
            </Col>
          </Row>
        </CarouselItem>
      );
    });

    return (
      <section className="testimonials-wrapper oblique">
        <Container className="text-center oblique-content">
          <Row>
            <Col>
              <h3>
              <span>
                Feedback
              </span>
                What client says
              </h3>
            </Col>
          </Row>
          <Row>
            <Col>
              <Carousel
                activeIndex={activeIndex}
                className="position-relative"
              >
                {slides}
                <CarouselIndicators
                  items={this.props.items}
                  activeIndex={activeIndex}
                  onClickHandler={this.goToIndex}
                />
              </Carousel>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}

Testimonials.propTypes = {
  items: PropTypes.array
};

Testimonials.defaultProps = {
  items: []
};

export default Testimonials;

