import React, { Component } from 'react';
import { graphql, Link, StaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import { Button, Card, CardText, Col, Container, Row } from 'reactstrap';

import '../assets/scss/components/_productSection.scss';
import '../assets/img/card-analyse-img.png';
import { FormattedMessage } from 'react-intl';

class ProductSection extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { language, activeLangKey } = this.props;

    return (
      <section className="product-section oblique text-center">
        <Container className="oblique-content">
          <StaticQuery
            query={graphql`
                    query CardImagesQuery {
                      productSectionDeJson {
                        preHeader
                        header
                        scouting {
                            image {
                                childImageSharp {
                                    fluid(maxWidth: 479, quality: 100) {
                                        ...GatsbyImageSharpFluid_withWebp
                                    }
                                }
                            }
                            content
                        }
                        analyse {
                            image {
                                childImageSharp {
                                    fluid(maxWidth: 477, quality: 100) {
                                        ...GatsbyImageSharpFluid_withWebp
                                    }
                                }
                            }
                            content
                        }
                      }
                      productSectionEnJson {
                        preHeader
                        header
                        scouting {
                            image {
                                childImageSharp {
                                    fluid(maxWidth: 479, quality: 100) {
                                        ...GatsbyImageSharpFluid_withWebp
                                    }
                                }
                            }
                            content
                        }
                        analyse {
                            image {
                                childImageSharp {
                                    fluid(maxWidth: 477, quality: 100) {
                                        ...GatsbyImageSharpFluid_withWebp
                                    }
                                }
                            }
                            content
                        }
                      }
                    }
                  `}
            render={data => {
              let content;
              if (language === 'de' || activeLangKey === 'de') {
                content = data.productSectionDeJson;
              } else {
                content = data.productSectionEnJson;
              }

              return (
                <Row>
                  <Col md={12}>
                    <h3>
                      <span>{content.preHeader}</span>
                      {content.header}
                    </h3>
                  </Col>
                  <Col md={6}>
                    <Card body className="text-center">
                      <Img width="100%" fluid={content.scouting.image.childImageSharp.fluid} alt="scouting product"/>

                      <CardText>
                        {content.scouting.content}
                      </CardText>
                      <div>
                        <Button
                          tag={Link}
                          to={`${this.props.activeLangKey}/products/scouting`}
                        >
                          <FormattedMessage id="learnMore"/>
                        </Button>
                      </div>
                    </Card>
                  </Col>
                  <Col md={6}>
                    <Card body className="text-center mt-4 mt-md-0">
                      <Img width="100%" fluid={content.analyse.image.childImageSharp.fluid} alt="scouting product"/>

                      <CardText>
                        {content.analyse.content}
                      </CardText>
                      <div>
                        <Button
                          tag={Link}
                          to={`${this.props.activeLangKey}/products/analysis`}
                        >
                          <FormattedMessage id="learnMore"/>
                        </Button>
                      </div>
                    </Card>
                  </Col>
                </Row>
              );
            }}
          />
        </Container>
      </section>
    );
  }
}

export default ProductSection;

