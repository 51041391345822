import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'reactstrap';
import HorizontalTimeline from 'react-horizontal-timeline';
import SwipeableViews from 'react-swipeable-views';
import Moment from 'moment';

import '../assets/scss/components/_milestones.scss';

class Milestones extends Component {

  static defaultProps = {
    items: {}
  };
  static propTypes = {
    items: PropTypes.object
  };

  constructor(props) {
    super(props);

    this.state = {
      value: 0,
      previous: 3
    };

  }

  static makeMilestones(milestones) {
    return milestones.map((entry, index) => {
      return (
        <div key={index} className='container justify-content-center col-md-9 col-12'>
          <h3 className="mb-0 font-weight-bold">
            <time className="mb-0 mt-0 content-time" dateTime={entry.date}>
              <div className="content-time-year">{Moment(entry.date).format('YYYY')}</div>
              <div className="content-time-moment">{Moment(entry.date).format('MMMM')}</div>
            </time>
          </h3>
          <p className="mb-0 mt-3 milestone-content">{entry.content}</p>
        </div>
      );
    });
  }

  render() {
    const HorizontalTimelineDate = this.props.items.map(row => row.date);
    return (
      <section className='milestones-wrapper oblique'>
        <Container className="oblique-content">
          <Row className="justify-content-center">
            <Col lg={10}>
              <div className="text-center">
                <h2 className="h3">
                  <span>Impect</span>
                  Milestones
                </h2>
                <div className="d-inline-block mb-2"/>


                <div style={{ width: '100%', height: '200px', margin: '0 auto' }}>
                  <HorizontalTimeline
                    index={this.state.value}
                    indexClick={(index) => {
                      this.setState({ value: index, previous: this.state.value });
                    }}
                    values={HorizontalTimelineDate}
                    styles={{ background: 'transparent', foreground: '#2888cd', outline: '#cdd0d4' }}
                    getLabel={(date) => {
                      return (
                        <div className="timeline-label">
                          <div className="timeline-label-year">
                            {Moment(date).format('YYYY')}
                          </div>
                          <div className="timeline-label-moment">
                            {Moment(date).format('MMMM')}
                          </div>
                        </div>
                      );
                    }}/>
                </div>

                <div className='text-center'>
                  <SwipeableViews
                    index={this.state.value}
                    onChangeIndex={(value, previous) => {
                      this.setState({
                        value,
                        previous
                      });
                    }}
                    resistance>
                    {Milestones.makeMilestones(this.props.items)}
                  </SwipeableViews>
                </div>

              </div>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}

export default Milestones;
