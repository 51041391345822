import React, { Component } from 'react';
import 'intl';
import Img from 'gatsby-image';

import ProductSection from './productSection';
import Testimonials from './testimonials';

import SocialLinks from './socialLinks';

import '../assets/scss/pages/_aboutUsPage.scss';
import { Col, Container, Row } from 'reactstrap';
import Milestones from './milestones';

class AboutUsPage extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { activeLangKey, headerImage, testimonials, milestones, intro, content } = this.props;


    return (
      <div className="about-us-wrapper">
        <section className="intro text-center">
          <div
            className="position-absolute social-links-wrapper"
          >
            <SocialLinks/>
          </div>
          <h1>
            <span>
              {intro.preHeader}
            </span>
            {intro.header}
          </h1>
          {headerImage ? (
            <Img
              className='img-fluid'
              style={{ position: 'initial' }}
              fixed={headerImage}
            />
          ) : null}
        </section>

        <section className="intro-text">
          <Container>
            <Row>
              <Col md={6}>
                <h2>
                  <span>{content.preHeader}</span>
                  {content.header}
                </h2>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <p>{content.contentOne}</p>
              </Col>

              <Col md={6}>
                <p>{content.contentTwo}</p>
              </Col>
            </Row>
          </Container>
        </section>

        <Milestones items={milestones} />

        <ProductSection
          activeLangKey={activeLangKey}
        />

        {/*<Testimonials
          activeLangKey={activeLangKey}
          items={testimonials}
        />*/}
      </div>
    );
  }
}

export default AboutUsPage;
